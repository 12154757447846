import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/Layout';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import Img from 'gatsby-image';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import Helmet from 'react-helmet';
import pic1 from '../assets/images/nikki_headshot_cropped.jpg';
import pic4 from '../assets/images/nikki_pushup_cropped_660.jpg';
import pic5 from '../assets/images/two_outside_660.jpg';
import virtual from '../assets/images/zoom_zoomed.jpg';
import consultation from '../assets/images/fitness_consultation_660.jpg';
// import social from '../assets/img/nikki_social.jpg';

import config from '../../config';
const IndexPage = () => {
  const items = [
    {
      id: 0,
      body: `"Nikki is an excellent fitness coach. She has a wonderful personality and is superbly trained to instruct and lead. She quickly learns what each client is capable of and gently pushes them to be their best. She also is aware of their physical limitations (troublesome joints, etc), and takes care to offer modifications that still target the muscles of interest. I look forward to my classes with Nikki, even though I often feel the sore muscles for a couple days after training with her. She is great with clients of all ages."`,
      attribution: 'Sherry Chester',
    },
    {
      id: 1,
      body: `"Nikki is a very positive influence, especially when I am in a negative frame of mind. She helps me overcome my self-doubts. Her online classes have been such a benefit for me. She helps me stay on track with my health and fitness goals, no matter where I am. After some recent knee surgery, she was able to adapt my fitness routine to meet my limited mobility. She is a great trainer!"`,
      attribution: 'Frank Muller',
    },
    {
      id: 2,
      body: `"I cannot recommend anyone as highly as Nikki Van Culin as a personal trainer.  She has an incredible knowledgeable base and is able to personalize that knowledge to the specific needs of each and every individual, whether one-on-one or within a class setting.  She motivates with her encouraging words, engages with her vibrant magnetism, and continually inspires to reach set goals.  Although I consider myself a 'fit' person, Nikki always brings me to that next level and leaves me longing for more."`,
      attribution: 'Vicki Verkaik',
    },
    {
      id: 3,
      body: `"I was in such terrible shape and overweight I couldn’t even do 3 minutes on a treadmill. I was so depressed and angry with myself. I knew I needed to make some changes. I had seen Nikki training another girl and was impressed with her progress. So when my husband asked me what I wanted for Christmas I said a personal trainer. So my journey began. It was the best gift I have ever received. Working with Nikki, I always say saved my life. I still workout with Nikki and through her continued knowledge and motivation I am in excellent physical and mental condition."`,
      attribution: 'Gena Jones',
    },
    {
      id: 4,
      body: `"Nikki has been my personal and small group trainer for 10 years! Her classes are FUN!! Whether it be to get in shape or lose weight, she always motivates her clients to challenge themselves to achieve their personal goals through healthy diet and exercise. Nikki tailors and modifies her work out sessions to the individual client allowing them to participate in various activities and classes with other clients despite their physical limitations or injuries.  Nikki creates a camaraderie among her clients that not only builds confidence and 'muscle' but also builds friendships. Nikki is not just a trainer/instructor but truly the definition of a personal health coach!"`,
      attribution: 'Eleanor Davina',
    },
    {
      id: 5,
      body: `"I have taken three (soon to be 4) sessions of boot camp with Nikki and I am currently in her Monday night IGT class.   I have also attended her Muscle Conditioning class and Bosu Burn class.  I cant say enough good things about my experiences with Nikki.  I am normally very unmotivated to work out, but...she is motivating and positive.  I struggle with many of the exercises and if Nikki isn’t telling me I CAN DO IT (and she is usually right, I can) then she is showing me how to adjust the exercise to fit my level of fitness.  I never feel awkward or uncomfortable when I’m in a class with Nikki.  She pushes me to do more and helps me realize I can be better.  And I am better, since day one I am much stronger and I feel better too. On a more personal level, Nikki is so very sweet and kind and she keeps me coming back...every day."`,
      attribution: 'Charlene deGroot',
    },
    {
      id: 6,
      body: `"Nikki rocks. Her upbeat mood, expert instructions and modifications make her workouts fun, safe and challenging. I just want to keep coming back for more!"`,
      attribution: 'Molly Punzo',
    },
    {
      id: 7,
      body: `"When you talk about results it means Nikki. Her training pushes you regardless of your fitness level. She is able to simultaneously train a beginner with an advanced and both are equally challenged. Above all her courteous and personable style makes it a pleasant experience. All this makes her workout an event in my week that I look forward to."`,
      attribution: 'Kathy Perkins',
    },
    {
      id: 8,
      body: `"Training with Nikki has been life changing for me. Her small group training sessions are a wonderful way to build muscle and increase cardio strength. Her workouts are innovative and attainable. She has a positive way to motivate and inspire you. I wouldn’t work out with anyone other than Nikki."`,
      attribution: 'Beth Lowell',
    },
    {
      id: 9,
      body: `"I have worked with Nikki for over 10 years. She has helped me through pregnancy, post baby, foot surgery and all in between. She is a great motivator and teacher, ensuring her workouts Work for me, while still pushing me to be better and stronger. I have done group and one-on-one and enjoyed them both. I can’t recommend her enough if you want to find your best self."`,
      attribution: 'Lynn McCullough',
    },
    {
      id: 10,
      body: `"I have taken Nikki's Small Group Training class several times a week for 5 years now.  No matter whether she has 1 or several attendees, she knows and understands that some need to modify an exercise.  During the class she will let those students know alternative exercises, but will get the same result.  Given the challenges of 2020, I have mostly been attending Nikki's classes remotely via Zoom. This has been a life saver! While working out with Nikki in person is the most desirable approach, her remote classes are a great alternative. No matter whether you attend her classes in person, or via Zoom, she makes it fun!"`,
      attribution: 'Judy',
    },
  ];
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnFocus: true,
    adaptiveHeight: true,
    autoplaySpeed: 7000,
  };

  const slides = items.map(item => {
    return (
      <div key={item.id}>
        <p className="testimonial-quote meet-text">{item.body}</p>
        <p className="testimonial-attribution">–{item.attribution}</p>
      </div>
    );
  });
  const data = useStaticQuery(graphql`
    query {
      nikki: file(relativePath: { eq: "nikki_headshot_cropped.jpg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            src
            sizes
            srcSet
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <Helmet>
        <meta
          name="description"
          content="Nikki Van Culin provides personal and small group fitness training in Lake County, FL. Sessions are offered in person and virtually (via Zoom)."
        />
        <meta property="og:image" content="https://i.imgur.com/WXEH5Wp.jpg" />
        <meta
          property="og:image:alt"
          content="Nikki Van Culin personal trainer at Envision Wellness logo"
        />
      </Helmet>
      <section id="banner">
        <div className="inner">
          <div className="hero-section">
            <h1>{config.heading}</h1>
            <h2>{config.subHeading}</h2>
            <AnchorLink
              to="/#contact"
              title="Get a consultation &rarr;"
              className="stripped hero-link"
              stripHash
            />
          </div>
        </div>
      </section>

      <section id="wrapper">
        <section id="one" className="wrapper spotlight style1">
          <div className="inner" id="about">
            <Img
              fluid={data.nikki.childImageSharp.fluid}
              alt="Nikki Van Culin profile"
              className="image"
            />

            <div className="content">
              <h3 className="major">Meet Nikki</h3>
              <p className="major-text meet-text">
                I have lived in Lake County, FL for over 15 years. I have a
                fun-loving husband and three busy kids. We live an active
                lifestyle and enjoy camping, hiking, mountain biking, kayaking,
                paddle boarding, pickle ball, skiing, and disc golf. We also
                like relaxing in the hot tub at the end of a long day.
              </p>
              <p className="major-text meet-text">
                I became a fitness instructor at the local YMCA soon after
                moving here. I have taught Zumba, Barre, Cycle, Stretch, HIIT,
                Cardio Conditioning, BOSU, Yoga, Boot Camp, Shallow/Deep Water
                Fitness, and Muscle Conditioning. Ten years ago, wanting to help
                people even more, I became a personal trainer. I have trained
                individuals and small groups at my home, local parks, and at the
                Y. Recently, because of gyms closing and stay-at-home orders, my
                home business has experienced amazing growth! I've begun using
                the Zoom platform to train students virtually and am offering
                all services safely in person, too. I can’t wait to meet you!
              </p>
              <Link to="#contact" className="special">
                Contact
              </Link>
            </div>
          </div>
        </section>

        <section id="four" className="wrapper alt style1">
          <div className="inner" id="services">
            <h3 className="major">Services</h3>
            <p className="major-text">
              I am offering accountability, encouragement, and motivation
              through personal and small group fitness training. Let me help you
              improve your body image, health, and quality of life!
            </p>

            <p className="major-text">
              My training services, whether one-on-one or in a small group, are
              offered in person and online (via Zoom). Students can be
              accommodated at my house, your house, or wherever you prefer. You
              can take me anywhere using a phone, tablet, computer, or smart TV!
            </p>

            <p className="major-text">
              Check out more detailed descriptions of each of my services below
              and{' '}
              <Link to="#contact">
                contact me to schedule an initial consultation!
              </Link>
            </p>
            <section className="features">
              <article>
                <img src={consultation} alt="" className="image" />

                <h4 className="major-subhead">Consultation</h4>
                <p className="services-p">
                  To get started, we will set up a fitness consultation so I can
                  get to know you and help you start achieving your goals. Then,
                  we can schedule an initial one-on-one session that will
                  include an assessment, and an overview of terminology and
                  proper form.
                </p>
              </article>
              <article>
                <img src={pic4} alt="Nikki doing a push-up" className="image" />

                <h4 className="major-subhead">One-on-One</h4>
                <p className="services-p">
                  Personal training tailored to your needs and goals. Schedule
                  one hour and half hour sessions based on your availability and
                  preference.
                </p>
              </article>
              <article>
                <img src={pic5} alt="" className="image" />

                <h4 className="major-subhead">Small Group</h4>
                <p className="services-p">
                  Classes offered mornings and evenings throughout the week. We
                  focus on overall fitness through strength training, interval
                  training, and cardio.
                </p>
              </article>
              <article>
                <img src={virtual} alt="" className="image" />

                <h4 className="major-subhead">Virtual</h4>
                <p className="services-p">
                  All training sessions are offered online via Zoom. Tune in to
                  live small group classes or schedule one-on-one sessions from
                  the comfort of your home.
                </p>
              </article>
            </section>
          </div>
        </section>

        <section id="three" className="wrapper spotlight style3">
          <div className="inner" id="testimonials">
            <div className="content">
              <h3 className="major">Testimonials</h3>
              <Slider {...settings}>{slides}</Slider>
            </div>
          </div>
        </section>
      </section>
    </Layout>
  );
};
export default IndexPage;
